import styled, { css, keyframes } from "styled-components";
import {
  fadeIn,
  fadeInDown,
  fadeInUp,
  rotateInDownLeft,
  rotateInDownRight,
  slideInUp,
  fadeInLeft,
  fadeInRight,
} from "react-animations";

export const FadeIn = styled.div`
  animation: 1s ${keyframes`${fadeIn}`};
`;

export const FadeInUp = styled.div`
  ${({ animate }) =>
    !animate
      ? ""
      : css`
          animation: 1s ${keyframes`${fadeInUp}`};
        `}
`;

export const FadeInDown = styled.div`
  ${({ animate }) =>
    !animate
      ? ""
      : css`
          animation: 1s ${keyframes`${fadeInDown}`};
        `}
`;

export const SlideInUp = styled.div`
  ${({ animate }) =>
    !animate
      ? ""
      : css`
          animation: 1s ${keyframes`${slideInUp}`};
        `}
`;

export const RotateInDownLeft = styled.div`
  animation: 1s ${keyframes`${rotateInDownLeft}`};
  position: relative;
  z-index: 900;
  top: 0;
  left: ${({ position }) => position};
`;

export const RotateInDownRight = styled.div`
  animation: 1s ${keyframes`${rotateInDownRight}`};
  position: relative;
  z-index: 950;
  top: 0;
  right: ${({ position }) => position};
`;

export const FadeInRight = styled.div`
  ${({ animate }) =>
    !animate
      ? ""
      : css`
          animation: 0.75s ${keyframes`${fadeInRight}`};
        `}
  position: relative;
  z-index: 900;
  top: 0;
  left: ${({ position }) => position};
  margin-right: ${({ margin, animate }) => (margin && animate ? margin : 0)};
`;

export const FadeInLeft = styled.div`
  ${({ animate }) =>
    !animate
      ? ""
      : css`
          animation: 0.75s ${keyframes`${fadeInLeft}`};
        `}
  position: relative;
  z-index: 950;
  top: 0;
  right: ${({ position }) => position};
  margin-left: ${({ margin, animate }) => (margin && animate ? margin : 0)};
`;
