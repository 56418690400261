import React, { useState } from "react";
import { makeStyles, Box, Grid, Hidden } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import CustomButton from "./CustomButton";
import CustomTitle from "./CustomTitle";
import FeatureWidget from "./FeatureWidget";
import features from "../services/FeaturesService";
import { featuresDelay } from "../config/constants";
import { Waypoint } from "react-waypoint";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: "6%",
    paddingRight: "6%",
    backgroundColor: theme.palette.background.paper,
    zIndex: 980,
    position: "relative",
  },
  bodyWrapper: {
    display: "block",
  },
}));

function Features({ width, openRegDialog }) {
  const classes = useStyles();

  const [selected, setSelected] = useState();
  const [selectedImage, setSelectedImage] = useState(
    process.env.PUBLIC_URL + "/assets/images/features-mobile.png"
  );

  const selectFeature = (index, element) => {
    setSelected(index);
    element?.image
      ? setSelectedImage(element?.image)
      : setSelectedImage(
          process.env.PUBLIC_URL + "/assets/images/features-mobile.png"
        );
  };

  const startAnimation = () => {
    for (let i = 0; i < features?.length; i++) {
      setTimeout(() => {
        selectFeature(i, features[i]);
      }, featuresDelay * i);
    }

    setTimeout(() => {
      selectFeature();
    }, featuresDelay * features?.length);
  };

  return (
    <Waypoint onEnter={startAnimation}>
      <Box
        className={classes.root}
        style={
          isWidthUp("md", width)
            ? //  web
              {
                paddingTop: 157,
                paddingBottom: 71,
              }
            : isWidthUp("sm", width)
            ? // ipad
              {
                paddingTop: 49,
                paddingBottom: 49,
              }
            : // mobile
              {
                paddingTop: 44,
                paddingBottom: 33,
              }
        }
      >
        <Box align="center">
          <CustomTitle className="mb-3" title="خدمات تطبيق خزنة" />
          <Box
            className={classes.bodyWrapper}
            style={
              isWidthUp("lg", width)
                ? {
                    backgroundImage: `url("${process.env.PUBLIC_URL}/assets/svgs/features-background.svg")`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center 0px",
                    backgroundSize: "84%",
                  }
                : {}
            }
          >
            <Hidden lgUp>
              <Grid item xs={12}>
                <img
                  src={selectedImage}
                  style={{ width: 250, objectFit: "scale-down" }}
                  alt=""
                />
              </Grid>
            </Hidden>

            <Grid container>
              {features?.slice(0, 2)?.map((element, index) => (
                <Grid item key={index} xs={12} sm={6} lg={2}>
                  <FeatureWidget
                    feature={element}
                    isSelected={selected === index}
                    onSelect={() => selectFeature(index, element)}
                  />
                </Grid>
              ))}

              <Hidden mdDown>
                <Grid item lg={4}>
                  <img src={selectedImage} alt="" />
                </Grid>
              </Hidden>

              {features?.slice(2, 4)?.map((element, index) => (
                <Grid item key={index + 2} xs={12} sm={6} lg={2}>
                  <FeatureWidget
                    feature={element}
                    isSelected={selected === index + 2}
                    onSelect={() => selectFeature(index + 2, element)}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <CustomButton
            className="mt-5"
            title="تواصل معنا"
            onClick={openRegDialog}
          />
        </Box>
      </Box>
    </Waypoint>
  );
}

export default withWidth()(Features);
