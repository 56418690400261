import React from "react";
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  List,
  Hidden,
  Divider,
} from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import CallIcon from "@material-ui/icons/Call";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import FooterContact from "./FooterContact";
import ScrollToTop from "./ScrollToTop";
import { khaznaEmail, khaznaPhone } from "../config/constants";
import ThemeColors from "../config/colors";
import FooterSocials from "./FooterSocials";
import FooterLink from "./FooterLink";

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: "8%",
    paddingRight: "8%",
    backgroundColor: ThemeColors.darkBlueColor,
  },
  logo: {
    filter: "brightness(0) invert(1)",
    objectFit: "contain",
  },
  socialIcon: {
    maxHeight: 17,
    objectFit: "contain",
  },
}));

function Footer(props) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box align={isWidthUp("lg", props.width) ? "start" : "center"}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/svgs/logo.svg`}
          className={classes.logo}
          style={
            isWidthUp("lg", props.width)
              ? //  web
                {
                  marginTop: 122.85,
                  marginBottom: 55.2,
                  width: 106.34,
                  height: 43.46,
                }
              : {
                  marginTop: 62.78,
                  marginBottom: 36.13,
                  width: 92,
                  height: 37.6,
                }
          }
          alt="Khazna"
        />
      </Box>

      <Box align={isWidthUp("lg", props.width) ? "start" : "center"}>
        <Grid container spacing={5}>
          <Grid item xs={12} lg={6}>
            <Box>
              <Typography variant="subtitle1">
                خزنة هي شركة مصرية هدفها تحسين وتوسيع نطاق الخدمات المالية
                للمصريين عن طريق تطبيق الهاتف المحمول, هدفنا هو توفير خدمات
                بسيطة و مريحة تساعد المستخدمين على سداد التزاماتهم بدون ضغوط أو
                أعباء وعلى الإدخار مما يساعد على تحقيق الأمان المالي ونشر
                الثقافة المالية
              </Typography>
              <Hidden mdDown>{<FooterSocials />}</Hidden>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} align="center">
            <List style={{ padding: 0 }}>
              <FooterLink to="NavBar" title="مزايا خزنة" />
              <FooterLink to="Features" title="خدمات تطبيق خزنة" />
              <FooterLink to="KhaznaCard" title="كارت خزنة" />
              <FooterLink to="Benefits" title="مزايا اخرى" />
              <FooterLink to="Customers" title="عملائنا" />
            </List>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <List style={{ padding: 0 }}>
              <FooterLink title="أتصل بنا" />

              <FooterContact title={khaznaEmail}>
                <MailIcon
                  color="primary"
                  fontSize={!isWidthUp("lg", props.width) ? "small" : "default"}
                />
              </FooterContact>
              <FooterContact title={khaznaPhone} isMail={false}>
                <CallIcon
                  color="primary"
                  fontSize={!isWidthUp("lg", props.width) ? "small" : "default"}
                />
              </FooterContact>
            </List>
          </Grid>
        </Grid>
      </Box>

      <Hidden mdDown>
        <ScrollToTop />
        <Divider />
      </Hidden>

      <Box align="center" style={{ paddingBottom: 40 }}>
        <Hidden lgUp>
          <Box style={{ marginBottom: 27 }}>{<FooterSocials />}</Box>
        </Hidden>
        <Typography
          style={{ marginTop: isWidthUp("lg", props.width) ? 55 : 0 }}
          variant="subtitle2"
        >
          جميع الحقوق محفوظة - 2022
        </Typography>
      </Box>

      <Hidden lgUp>
        <ScrollToTop />
      </Hidden>
    </Box>
  );
}

export default withWidth()(Footer);
