import React, { useState } from "react";
import { makeStyles, Box, Grid } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { Waypoint } from "react-waypoint";
import CustomTitle from "./CustomTitle";
import CustomButton from "./CustomButton";
import ServiceWidget from "./ServiceWidget";
import services from "../services/FeedsService";
import { FadeInUp } from "../config/animations";
import { khaznaCardBenefitsDelay } from "../config/constants";

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: "10%",
    paddingRight: "10%",
  },
}));

function Services({ width, openRegDialog }) {
  const classes = useStyles();

  const [animation, setAnimation] = useState(false);

  const startAnimation = () => {
    setTimeout(() => {
      setAnimation(true);
    }, khaznaCardBenefitsDelay);
  };

  return (
    <Waypoint onEnter={startAnimation}>
      <div>
        {animation && (
          <FadeInUp animate={true}>
            <Box
              className={classes.root}
              style={
                isWidthUp("md", width)
                  ? //  web
                    {
                      paddingBottom: 103,
                    }
                  : isWidthUp("sm", width)
                  ? // ipad
                    {
                      paddingBottom: 60,
                    }
                  : // mobile
                    {
                      paddingBottom: 70,
                    }
              }
            >
              <Box align="center">
                <CustomTitle title="مزايا كارت خزنة" />

                <Grid container spacing={1}>
                  {services?.map((service, index) => (
                    <Grid
                      item
                      xs={12}
                      key={index}
                      dir={index % 2 === 1 ? "rtl" : "ltr"}
                    >
                      <ServiceWidget
                        service={service}
                        isRight={index % 2 === 1}
                        isFirst={index === 0}
                      />
                    </Grid>
                  ))}
                </Grid>

                <CustomButton
                  className="mt-5"
                  title="تواصل معنا"
                  onClick={openRegDialog}
                />
              </Box>
            </Box>
          </FadeInUp>
        )}
      </div>
    </Waypoint>
  );
}

export default withWidth()(Services);
