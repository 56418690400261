import React from "react";
import { Typography } from "@material-ui/core";

export default function CustomTitle({
  variant = "h1",
  title,
  className,
  style,
}) {
  return (
    <Typography variant={variant} style={style} className={className}>
      {title}
    </Typography>
  );
}
