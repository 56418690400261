const benefits = [
  {
    title: "الأمان",
    description: "معاملات مالية آمنة",
    image: `${process.env.PUBLIC_URL}/assets/svgs/security.svg`,
  },
  // {
  //   title: "مفردات المرتب",
  //   description: "الاطلاع على مفردات المرتب من خلال التطبيق",
  //   image: `${process.env.PUBLIC_URL}/assets/svgs/salary-2.svg`,
  // },
  {
    title: "خدمة العملاء",
    description: "خدمة عملاء مميزة ٢٤/٧",
    image: `${process.env.PUBLIC_URL}/assets/svgs/customer-service.svg`,
  },
  {
    title: "تلبية احتياجات الشركة",
    description: "تخصيص خدمات مالية تناسب احتياجات الشركة",
    image: `${process.env.PUBLIC_URL}/assets/svgs/company.svg`,
  },
];

export default benefits;
