// Network constants
export const BASE_URL = "https://api.khazna.app/";
export const REQUEST_TIMEOUT = 10 * 1000; // 10 secs

// Khazna contact details
export const khaznaEmail = "support@khazna.app";
export const khaznaPhone = "19524";

export const khaznaFacebook = "https://facebook.com/KhaznaApp/";
export const khaznaLinkedin = "https://www.linkedin.com/company/khazna/";

// All delays are in milliseconds
export const loaderDelay = 4000;
export const landingTextDelay = loaderDelay + 500;
export const landingImageDelay = landingTextDelay + 2000;
export const landingFeaturesDelay = landingImageDelay + 1500;

export const featuresDelay = 3500;

export const khaznaCardTextDelay = 500;
export const khaznaCardImageDelay = khaznaCardTextDelay + 1000;
export const khaznaCardImageSecondDelay = khaznaCardImageDelay + 1000;
export const khaznaCardBenefitsDelay = khaznaCardImageSecondDelay + 500;
