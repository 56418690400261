import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import {
  RotateInDownRight,
  RotateInDownLeft,
  FadeInLeft,
  FadeInRight,
} from "../config/animations";

const useStyles = makeStyles(() => ({
  mainImage: {
    maxWidth: "100%",
    objectFit: "scale-down",
  },
}));

function LandingCentralImage({
  newHeight,
  width,
  style,
  startFirstAnimation,
  startSecondAnimation,
}) {
  const classes = useStyles();

  const inPosition = isWidthUp("lg", width)
    ? "20rem"
    : isWidthUp("md", width)
    ? "10rem"
    : isWidthUp("sm", width)
    ? "7.5rem"
    : "4rem";

  const outPosition = isWidthUp("lg", width)
    ? "-15rem"
    : isWidthUp("md", width)
    ? "-7rem"
    : isWidthUp("sm", width)
    ? "7.5rem"
    : "4rem";

  const margin = isWidthUp("lg", width)
    ? "4rem"
    : isWidthUp("md", width)
    ? "8rem"
    : isWidthUp("sm", width)
    ? 0
    : 0;

  const animateSecondContent =
    startSecondAnimation &&
    (width === "md" || width === "lg" || width === "xl");

  return (
    <React.Fragment>
      {startFirstAnimation && (
        <Box
          ref={(ref) => {
            newHeight && newHeight(ref?.clientHeight);
          }}
          style={{
            ...style,
            marginTop: isWidthUp("md", width)
              ? 183
              : isWidthUp("sm", width)
              ? 30
              : 0,
          }}
        >
          <Box className="d-flex" style={{ position: "relative" }}>
            <RotateInDownRight
              position={!animateSecondContent ? inPosition : 0}
            >
              <FadeInLeft
                animate={animateSecondContent}
                margin={margin}
                position={animateSecondContent ? outPosition : 0}
              >
                <img
                  src={`${process.env.PUBLIC_URL}assets/images/landing-card.png`}
                  alt=""
                  className={classes.mainImage}
                />
              </FadeInLeft>
            </RotateInDownRight>
            <RotateInDownLeft position={!animateSecondContent ? inPosition : 0}>
              <FadeInRight
                animate={animateSecondContent}
                margin={margin}
                position={animateSecondContent ? outPosition : 0}
              >
                <img
                  src={`${process.env.PUBLIC_URL}assets/images/landing-mobile.png`}
                  alt=""
                  className={classes.mainImage}
                />
              </FadeInRight>
            </RotateInDownLeft>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
}

export default withWidth()(LandingCentralImage);
