import React from "react";
import { Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";

export default function CustomButton({
  color = "secondary",
  onClick,
  padding = "15px 55px",
  margin,
  variant = "contained",
  textVariant = "button",
  title,
  className,
  style,
  type,
  isDisabled = false,
}) {
  return (
    <Button
      type={type}
      variant={variant}
      color={color}
      className={className}
      style={{
        padding: padding,
        margin: margin,
        borderRadius: 10,
        ...style,
      }}
      onClick={onClick}
      disabled={isDisabled}
    >
      <Typography
        className="font-weight-bold"
        variant={textVariant}
        color="inherit"
      >
        {title}
      </Typography>
    </Button>
  );
}
