export const khaznaCardFeatures = [
  "اقبض بدري وقتي!",
  "تحويل المرتبات",
  "سحب و ايداع بدون رسوم",
  "تفاصيل الكارت بضغطة",
];

export const khaznaAppFeatures = [
  {
    title: "اقبض بدري",
    image: `${process.env.PUBLIC_URL}/assets/svgs/salary-green.svg`,
  },
  {
    title: "ادفع فواتيرك",
    image: `${process.env.PUBLIC_URL}/assets/svgs/bills-green.svg`,
  },
  {
    title: "عروض التقسيط",
    image: `${process.env.PUBLIC_URL}/assets/svgs/instalments-green.svg`,
  },
];
