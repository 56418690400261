import React, { useState } from "react";
import {
  Dialog,
  Typography,
  Box,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import { GeneralService } from "../services/GeneralService";
import { User } from "../models/User";
import CustomTextField from "./CustomTextField";
import CustomButton from "./CustomButton";
import ThemeColors from "../config/colors";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.default,
  },
}));

const RegistrationDialog = ({ open = false, onClose }) => {
  const classes = useStyles();

  const [success, setSuccess] = useState(false);

  const register = async (data) => {
    const result = await GeneralService.register(data);
    setSuccess(result);
  };

  const handleClose = () => {
    setSuccess(false);
    onClose();
  };

  const SuccessView = () => (
    <Box
      style={{
        height: 503.69,
      }}
      className="d-flex flex-column align-items-center justify-content-center px-2"
    >
      <img
        src={process.env.PUBLIC_URL + "/assets/svgs/checkmark-green.svg"}
        className="mb-5"
        alt=""
      />
      <Typography variant="body2" color="textSecondary" className="mb-4">
        تم ارسال بياناتك بنجاح!
      </Typography>
    </Box>
  );

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        style: { borderRadius: 14 },
      }}
      fullWidth
    >
      <Formik
        initialValues={{
          ...User.initialValue(),
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("يرجى إدخال هذا الحقل"),
          email: Yup.string()
            .email("يرجى إدخال بريد إلكتروني صحيح")
            .required("يرجى إدخال هذا الحقل"),
          mobile: Yup.number()
            .integer("يرجى حذف النقاط")
            .typeError("يرجى إدخال رقم صحيح")
            .min(0, "هذه القيمة يجب ان تكون 11 ارقام")
            .required("يرجى إدخال هذا الحقل")
            .test(
              "len",
              "هذه القيمة يجب ان تكون 11 ارقام",
              (val) => val?.toString()?.length === 10
            ),
          company: Yup.string().required("يرجى إدخال هذا الحقل"),
          job: Yup.string().required("يرجى إدخال هذا الحقل"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const dataObj = {
              msg: JSON.stringify(User.toMap(values)),
              sender: "home-app",
            };
            await register(dataObj);
            setStatus({ success: true });
            setSubmitting(false);
          } catch (e) {
            setStatus({ success: false });
            setErrors({ submit: e.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            className={classes.background}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>

            {success ? (
              <SuccessView />
            ) : (
              <Box className="px-5 pb-5 mb-2">
                <Box align="center">
                  <Typography variant="h5">تواصل معنا</Typography>

                  <Typography
                    variant="subtitle1"
                    className="mb-4"
                    style={{
                      color: ThemeColors.accentBlueColor,
                    }}
                  >
                    برجاء إدخال البيانات وسيتم التواصل معك في اقرب وقت
                  </Typography>
                </Box>

                <CustomTextField
                  id="name"
                  title="الأسم"
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={errors.name}
                  touched={touched.name}
                />
                <CustomTextField
                  id="email"
                  title="البريد الإلكتروني"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={errors.email}
                  touched={touched.email}
                />
                <CustomTextField
                  id="mobile"
                  title="رقم الموبايل"
                  value={values.mobile}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={errors.mobile}
                  touched={touched.mobile}
                />
                <CustomTextField
                  id="company"
                  title="الشركة"
                  value={values.company}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={errors.company}
                  touched={touched.company}
                />
                <CustomTextField
                  id="job"
                  title="الوظيفة"
                  value={values.job}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={errors.job}
                  touched={touched.job}
                />

                <Box align="center">
                  {errors.submit && (
                    <Typography
                      color="error"
                      align="center"
                      className="mt-4"
                      variant="subtitle1"
                    >
                      {errors.submit}
                    </Typography>
                  )}

                  <CustomButton
                    title="ارسال"
                    textVariant="body2"
                    isDisabled={isSubmitting}
                    type="submit"
                    padding="12px 55px"
                    className="mt-4"
                  />
                </Box>
              </Box>
            )}
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default RegistrationDialog;
