import React from "react";
import { Typography, Box, makeStyles } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

function FeatureWidget({ feature, isSelected, onSelect, width }) {
  const classes = makeStyles((theme) => ({
    animation: {
      transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  }))();

  return (
    <Box align="center" className="mx-4" onClick={onSelect}>
      <img
        src={isSelected ? feature.secondaryIcon : feature.icon}
        alt=""
        className={classes.animation}
        style={
          isWidthUp("md", width)
            ? //  web
              {
                width: isSelected ? 198 : 162,
                height: isSelected ? 198 : 162,
                marginTop: isSelected ? 22 : 40,
                marginBottom: isSelected ? 0 : 18,
                objectFit: "contain",
                cursor: "pointer",
              }
            : isWidthUp("sm", width)
            ? // ipad
              {
                width: isSelected ? 125 : 105,
                height: isSelected ? 125 : 105,
                marginTop: isSelected ? 20 : 40,
                objectFit: "contain",
                cursor: "pointer",
              }
            : // mobile
              {
                width: isSelected ? 110 : 90,
                height: isSelected ? 110 : 90,
                marginTop: isSelected ? 18 : 40,
                objectFit: "contain",
                cursor: "pointer",
              }
        }
      />

      <Typography variant="h6" className="font-weight-bold">
        {feature.title}
      </Typography>
      <Typography variant="body2">{feature.description}</Typography>
    </Box>
  );
}

export default withWidth()(FeatureWidget);
