import React from "react";
import {
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

function LandingFeatureItem({ element, addElementsPadding }) {
  return (
    <ListItem
      style={{
        justifyContent: "flex-end",
      }}
      className={addElementsPadding ? "py-3" : ""}
    >
      <ListItemIcon>
        <img
          src={
            element?.image ??
            `${process.env.PUBLIC_URL}/assets/svgs/checkmark.svg`
          }
          alt=""
          style={{
            width: 40,
            objectFit: "scale-down",
          }}
        />
      </ListItemIcon>
      <ListItemText
        style={{
          textAlign: "right",
        }}
      >
        <Typography
          color="textSecondary"
          className="font-weight-bold"
          variant="body1"
        >
          {element?.title ?? element}
        </Typography>
      </ListItemText>
    </ListItem>
  );
}

export default LandingFeatureItem;
