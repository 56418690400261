import React from "react";
import { makeStyles, Box, Grid } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import CustomTitle from "./CustomTitle";
import CustomButton from "./CustomButton";
import BenefitWidget from "./BenefitWidget";
import benefits from "../services/BenefitsService";
import Slider from "react-slick";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: "10%",
    paddingRight: "10%",
    backgroundColor: theme.palette.background.paper,
  },
}));

function Benefits({ width, openRegDialog }) {
  const classes = useStyles();
  return (
    <Box
      className={classes.root}
      style={
        isWidthUp("md", width)
          ? //  web
            {
              paddingTop: 142,
              paddingBottom: 190,
            }
          : isWidthUp("sm", width)
          ? // ipad
            {
              paddingTop: 55,
              paddingBottom: 49,
            }
          : // mobile
            {
              paddingTop: 62,
              paddingBottom: 125,
            }
      }
    >
      <Box align="center">
        <CustomTitle
          style={{
            marginBottom: 50,
          }}
          title="مزايا اخرى"
        />

        <Slider
          dots={true}
          arrows={false}
          infinite={true}
          speed={500}
          slidesToShow={
            isWidthUp("md", width) ? 3 : isWidthUp("sm", width) ? 2 : 1
          }
          slidesToScroll={
            isWidthUp("md", width) ? 3 : isWidthUp("sm", width) ? 2 : 1
          }
        >
          {benefits?.map((element, index) => 
               (<Grid item key={index} xs={12}>
                <BenefitWidget benefit={element} />
              </Grid>)
          )}
        </Slider>

        <CustomButton
          style={
            isWidthUp("md", width)
              ? //  web
                {
                  marginTop: 130,
                }
              : isWidthUp("sm", width)
              ? // ipad
                {
                  marginTop: 90,
                }
              : // mobile
                {
                  marginTop: 90,
                }
          }
          title="تواصل معنا"
          onClick={openRegDialog}
        />
      </Box>
    </Box>
  );
}

export default withWidth()(Benefits);
