import React, { useState } from "react";
import {
  IconButton,
  InputAdornment,
  TextField,
  makeStyles,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const CustomTextField = (props) => {
  const {
    id,
    variant = "outlined",
    title,
    value,
    placeholder,
    type = "text",
    onBlur,
    onChange,
    error,
    touched,
    isDisabled = false,
    required = false,
    margin = "dense",
    size = "medium",
    min = "0",
    fullWidth = true,
    className,
  } = props;

  const classes = makeStyles((theme) => ({
    errorText: {
      fontSize: theme.typography.subtitle2.fontSize,
    },
    inputText: {
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: 700,
    },
    rootFieldLabel: {
      color: theme.palette.text.hint,
      fontSize: theme.typography.subtitle1.fontSize,
    },
  }))();

  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <React.Fragment>
      {type === "password" ? (
        <FormControl
          variant="outlined"
          className={className}
          size={size}
          fullWidth={fullWidth}
          margin={margin}
          disabled={isDisabled}
        >
          <InputLabel htmlFor={id}>{title}</InputLabel>
          <OutlinedInput
            id={id}
            required={required}
            label={title}
            type={showPassword ? "text" : "password"}
            placeholder={placeholder}
            error={touched && error != null ? true : false}
            onChange={onChange}
            value={value}
            fullWidth={fullWidth}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {error && touched && (
            <FormHelperText className={classes.errorText} id={id + "-helper"}>
              {error}
            </FormHelperText>
          )}
        </FormControl>
      ) : (
        <TextField
          {...props}
          touched={null}
          id={id}
          required={required}
          className={className}
          variant={variant}
          label={title}
          size={size}
          placeholder={placeholder}
          error={touched && error != null ? true : false}
          name={id}
          onBlur={onBlur}
          onChange={onChange}
          type={type}
          value={value}
          disabled={isDisabled}
          min={min}
          margin={margin}
          fullWidth={fullWidth}
          helperText={touched && error ? error : null}
          InputLabelProps={{
            classes: {
              root: classes.rootFieldLabel,
            },
          }}
          InputProps={{
            classes: {
              root: classes.rootFieldInput,
              input: classes.inputText,
            },
          }}
          FormHelperTextProps={{
            classes: {
              root: classes.rootFieldInput,
              error: classes.errorText,
            },
          }}
        />
      )}
    </React.Fragment>
  );
};

export default CustomTextField;
