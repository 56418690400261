import React from "react";
import { AppBar, makeStyles, Toolbar } from "@material-ui/core";
import NavBarElements from "./NavBarElements";
import { Link } from "react-router-dom";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

const useStyles = makeStyles(() => ({
  appbar: {
    backgroundColor: "rgba(255, 255, 255, 0.85)",
  },
  smallToolbar: {
    paddingLeft: 32,
    paddingRight: 32,
    justifyContent: "space-between",
    paddingTop: 30,
    paddingBottom: 20,
  },
  smallLogo: {
    height: 30,
    objectFit: "contain",
  },
  largeToolbar: {
    paddingLeft: "8%",
    paddingRight: "8%",
    justifyContent: "space-between",
    paddingTop: 30,
    paddingBottom: 20,
  },
  largeLogo: {
    width: 145,
    objectFit: "contain",
  },
}));

function NavBar({ children, width, openRegDialog }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <AppBar elevation={0} className={classes.appbar}>
        <Toolbar
          className={
            isWidthUp("lg", width) ? classes.largeToolbar : classes.smallToolbar
          }
        >
          <Link to="/">
            <img
              alt="Khazna"
              src={`${process.env.PUBLIC_URL}assets/svgs/logo.svg`}
              className={
                isWidthUp("lg", width) ? classes.largeLogo : classes.smallLogo
              }
            />
          </Link>
          <NavBarElements openRegDialog={openRegDialog} />
        </Toolbar>
      </AppBar>
      <Toolbar />
      {children}
    </React.Fragment>
  );
}

export default withWidth()(NavBar);
