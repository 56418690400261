import React from "react";
import { makeStyles, Fab, Box, Typography } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { Link as ScrollLink } from "react-scroll";

function ScrollToTop(props) {
  const useStyles = makeStyles((theme) => ({
    scrollArrow: {
      color: theme.palette.background.default,
    },
  }));

  const classes = useStyles();

  return (
    <Box
      className={
        "d-flex flex-column align-items-" +
        (isWidthUp("lg", props.width) ? "end" : "center")
      }
      style={{
        paddingBottom: "15px",
      }}
    >
      <span
        className="d-flex flex-column align-items-center"
        style={{
          width: "min-content",
        }}
      >
        <ScrollLink to="NavBar" spy={true} smooth={true} duration={500}>
          <Fab color="secondary" className={classes.scrollIcon}>
            <ArrowUpwardIcon className={classes.scrollArrow} />
          </Fab>
        </ScrollLink>
        <Typography
          style={{ display: "block", paddingTop: "15px" }}
          variant="subtitle2"
        >
          لأعلي
        </Typography>
      </span>
    </Box>
  );
}

export default withWidth()(ScrollToTop);
