import React from "react";
import { Typography, Box } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

function BenefitWidget({ benefit, width }) {
  return (
    <Box
      align="center"
      className="mx-4"
      style={{
        marginTop: 40,
        marginBottom: 70,
      }}
    >
      <img
        src={benefit.image}
        alt=""
        style={
          isWidthUp("md", width)
            ? //  web
              {
                width: 169,
                height: 169,
                objectFit: "contain",
              }
            : isWidthUp("sm", width)
            ? // ipad
              {
                width: 97,
                height: 97,
                objectFit: "contain",
              }
            : // mobile
              {
                width: 169,
                height: 169,
                objectFit: "contain",
              }
        }
      />

      <Typography variant="h6" className="font-weight-bold">
        {benefit.title}
      </Typography>
      <Typography variant="body2">{benefit.description}</Typography>
    </Box>
  );
}

export default withWidth()(BenefitWidget);
