import React from "react";
import {
  makeStyles,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import RoundedIcon from "./RoundedIcon";

const useStyles = makeStyles((theme) => ({
  customListTile: {
    margin: 0,
    textAlign: "center",
  },
  socialIconWrapper: {
    backgroundColor: theme.palette.primary.contrastText,
    padding: 5,
  },
}));

function FooterContact({ width, children, title }) {
  const classes = useStyles();

  return (
    <ListItem
      className={classes.customListTile}
      style={{
        display: !isWidthUp("lg", width) ? "block" : "flex",
      }}
    >
      <ListItemIcon style={{ minWidth: isWidthUp("lg", width) ? 56 : 0 }}>
        <RoundedIcon>{children}</RoundedIcon>
      </ListItemIcon>
      <ListItemText align={isWidthUp("lg", width) ? "start" : "center"}>
        <Typography
          dir="ltr"
          variant="subtitle1"
          style={{ textAlign: isWidthUp("lg", width) ? "end" : "center" }}
        >
          {title}
        </Typography>
      </ListItemText>
    </ListItem>
  );
}

export default withWidth()(FooterContact);
