import React from "react";
import { makeStyles, Box, ListItemIcon } from "@material-ui/core";
import RoundedIcon from "./RoundedIcon";
import { khaznaFacebook, khaznaLinkedin } from "../config/constants";

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: "8%",
    paddingRight: "8%",
  },
  logo: {
    filter: "brightness(0) invert(1)",
    objectFit: "contain",
  },
  socialIcon: {
    maxHeight: 17,
    objectFit: "contain",
  },
}));

function FooterSocials() {
  const classes = useStyles();

  const SocialElement = ({ title, url, image }) => {
    return (
      <ListItemIcon style={{ minWidth: 0, marginLeft: 8 }}>
        <a target="_blank" rel="noopener noreferrer" href={url}>
          <RoundedIcon>
            <img src={image} className={classes.socialIcon} alt={title} />
          </RoundedIcon>
        </a>
      </ListItemIcon>
    );
  };

  return (
    <Box style={{ marginTop: 50 }}>
      <SocialElement
        title="Facebook"
        url={khaznaFacebook}
        image={`${process.env.PUBLIC_URL}assets/svgs/facebook.svg`}
      />

      <SocialElement
        title="Linkedin"
        url={khaznaLinkedin}
        image={`${process.env.PUBLIC_URL}assets/svgs/linkedin.svg`}
      />
    </Box>
  );
}

export default FooterSocials;
