import axios from "../config/network";

export const GeneralService = {
  register: (data) => register(data),
};

const register = async (data) => {
  try {
    await axios.post("/admin/anonymous/mail", data);
    return true;
  } catch (err) {
    throw new Error("حدث خطأ، برجاء اعادة المحاولة فى وقت لاحق");
  }
};
