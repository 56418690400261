import React, { useState } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { Waypoint } from "react-waypoint";
import CustomTitle from "./CustomTitle";
import { FadeIn, FadeInDown } from "../config/animations";
import {
  khaznaCardTextDelay,
  khaznaCardImageDelay,
  khaznaCardImageSecondDelay,
} from "../config/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: "6%",
    paddingRight: "6%",
  },
  animation: {
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

function KhaznaCard({ width }) {
  const classes = useStyles();

  const [startTextAnimation, setStartTextAnimation] = useState(false);
  const [startImageAnimation, setStartImageAnimation] = useState(false);
  const [startImageSecondAnimation, setStartImageSecondAnimation] =
    useState(false);

  const startAnimation = () => {
    setTimeout(() => {
      setStartTextAnimation(true);
    }, khaznaCardTextDelay);

    setTimeout(() => {
      setStartImageAnimation(true);
    }, khaznaCardImageDelay);

    setTimeout(() => {
      setStartImageSecondAnimation(true);
    }, khaznaCardImageSecondDelay);
  };

  const imageStyle = isWidthUp("md", width)
    ? //  web
      {
        marginLeft: 20,
        marginRight: 20,
      }
    : isWidthUp("sm", width)
    ? // ipad
      {
        height: 35,
        width: 120,
        objectFit: "scale-down",
      }
    : // mobile
      {
        height: 23,
        width: 79,
        objectFit: "scale-down",
        marginLeft: 5,
        marginRight: 5,
      };

  return (
    <Waypoint onEnter={startAnimation}>
      <Box
        className={classes.root}
        style={
          isWidthUp("md", width)
            ? //  web
              {
                paddingTop: 144,
                paddingBottom: 140,
              }
            : isWidthUp("sm", width)
            ? // ipad
              {
                paddingTop: 83,
                paddingBottom: 99,
              }
            : // mobile
              {
                paddingTop: 89,
                paddingBottom: 122,
              }
        }
      >
        <Box align="center">
          <CustomTitle title="كارت خزنة" />

          {startTextAnimation && (
            <FadeInDown animate={true}>
              <Typography variant="h6" className="my-3">
                مقدم لكم من
              </Typography>
              <Box className="d-flex align-items-center justify-content-center">
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/logo-simple.png"}
                  alt="Khazna"
                  style={imageStyle}
                />
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/adib.png"}
                  alt="ADIB"
                  style={imageStyle}
                />
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/meeza.png"}
                  alt="Meeza"
                  style={imageStyle}
                />
              </Box>
            </FadeInDown>
          )}

          {startImageAnimation && (
            <FadeIn animate={true}>
              <img
                src={process.env.PUBLIC_URL + "/assets/images/khazna-card.png"}
                alt=""
                className={classes.animation}
                style={{
                  width: isWidthUp("md", width)
                    ? startImageSecondAnimation
                      ? "55%"
                      : "65%"
                    : "100%",
                  objectFit: "scale-down",
                }}
              />
            </FadeIn>
          )}

          {startImageSecondAnimation && (
            <FadeIn animate={true}>
              <Typography
                variant="h3"
                className="my-3"
                style={
                  isWidthUp("md", width)
                    ? //  web
                      {
                        marginLeft: "25%",
                        marginRight: "25%",
                      }
                    : isWidthUp("sm", width)
                    ? // ipad
                      {
                        marginLeft: "10%",
                        marginRight: "10%",
                      }
                    : // mobile
                      {
                        marginLeft: "4%",
                        marginRight: "4%",
                      }
                }
              >
                يتم إصدار الكارت مجانًا وتسليمه بمحل عمل الموظف عن طريق موظفي
                شركة خزنة.
              </Typography>
            </FadeIn>
          )}
        </Box>
      </Box>
    </Waypoint>
  );
}

export default withWidth()(KhaznaCard);
