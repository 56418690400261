import React, { useState } from "react";
import {
  makeStyles,
  IconButton,
  Typography,
  Box,
  Hidden,
  MenuItem,
  Select,
  ListItem,
  ListItemText,
  SwipeableDrawer,
  List,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link as ScrollLink } from "react-scroll";
import CustomButton from "../components/CustomButton";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    color: theme.palette.primary.main,
  },
  drawer: {
    background: theme.palette.background.default,
  },
  link: {
    cursor: "pointer",
    display: "inline",
    marginLeft: 20,
    marginRight: 20,
    textDecoration: "none",
  },
  flag: {
    marginLeft: 10,
    marginRight: 10,
    height: 19,
    objectFit: "scale-down",
  },
  languageSelector: {
    marginLeft: 27,
  },
  languageText: {
    color: theme.palette.text.hint,
  },
}));

const languages = [
  {
    label: "عربي",
    value: "ar",
    icon: `${process.env.PUBLIC_URL}assets/icons/eg.png`,
  },
];

const links = [
  {
    title: "تطبيق خزنة",
    path: "Features",
  },
  {
    title: "كارت خزنة",
    path: "KhaznaCard",
  },
];

export default function NavBarElements({ openRegDialog }) {
  const classes = useStyles();

  const [language, setLanguage] = useState("ar");
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
    if (isDrawerOpen) {
      setIsDrawerOpen(false);
    }
  };

  const toggleDrawer = (value) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsDrawerOpen(value);
  };

  const linksList = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {links.map((link, index) => (
          <ListItem
            button
            component={ScrollLink}
            to={link.path}
            spy={true}
            smooth={true}
            duration={500}
            key={index}
            onClick={() => setIsDrawerOpen(false)}
          >
            <ListItemText primary={link.title} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const languageSelector = () => {
    return (
      <Select
        value={language}
        onChange={handleLanguageChange}
        disableUnderline
        className={classes.languageSelector}
      >
        {languages.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Typography
              component="span"
              variant="subtitle2"
              className={classes.languageText}
            >
              {option.label}
            </Typography>
            <img
              alt={option.label}
              src={option.icon}
              className={classes.flag}
            />
          </MenuItem>
        ))}
      </Select>
    );
  };

  const loginBtn = () => {
    return (
      <CustomButton
        textVariant="subtitle2"
        title="تواصل معنا"
        onClick={() => {
          setIsDrawerOpen(false);
          openRegDialog();
        }}
      />
    );
  };

  return (
    <React.Fragment>
      {/* Web View */}
      <Hidden mdDown>
        <Box>
          {links.map((link, index) => (
            <ScrollLink
              key={index}
              to={link.path}
              spy={true}
              smooth={true}
              duration={500}
              className="mx-5"
            >
              <Typography
                className={classes.link}
                component="span"
                variant="body1"
              >
                {link.title}
              </Typography>
            </ScrollLink>
          ))}
        </Box>
        <Box>
          {loginBtn()}
          {languageSelector()}
        </Box>
      </Hidden>

      {/* Mobile View */}
      <Hidden lgUp>
        <IconButton
          edge="end"
          color="primary"
          aria-label="menu"
          onClick={toggleDrawer(true)}
        >
          <MenuIcon className={classes.menuButton} />
        </IconButton>

        <SwipeableDrawer
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          classes={{ paper: classes.drawer }}
        >
          {linksList()}

          <List>
            <ListItem>{loginBtn()}</ListItem>
            <ListItem>{languageSelector()}</ListItem>
          </List>
        </SwipeableDrawer>
      </Hidden>
    </React.Fragment>
  );
}
