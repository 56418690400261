import React from "react";
import { Typography, Box } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { FadeIn, SlideInUp } from "../config/animations";

function LandingCentralText({ width, textAnimation, imageAnimation }) {
  const Content = () => (
    <React.Fragment>
      <Typography
        variant="h1"
        style={
          isWidthUp("md", width)
            ? //  web
              {
                fontSize: 110,
                textAlign: "center",
              }
            : isWidthUp("sm", width)
            ? // ipad
              {
                fontSize: 80,
                textAlign: "center",
              }
            : // mobile
              {
                fontSize: 49,
                textAlign: "center",
              }
        }
      >
        خزنة دايماً معاك
      </Typography>
      <Typography
        variant="h2"
        style={{
          marginTop: 38,
          marginBottom: marginBottom,
          textAlign: "center",
        }}
      >
        مزايا مالية للموظفين والعمالة والمقاولين بدون أي تكلفة أو ضمانات من
        الشركة
      </Typography>
    </React.Fragment>
  );

  const marginBottom = isWidthUp("xl", width)
    ? 0
    : isWidthUp("lg", width)
    ? 0
    : isWidthUp("md", width)
    ? 0
    : isWidthUp("sm", width)
    ? 20
    : 50;

  const marginTop = isWidthUp("lg", width)
    ? !imageAnimation
      ? 420
      : 200
    : isWidthUp("md", width)
    ? !imageAnimation
      ? 290
      : 100
    : isWidthUp("sm", width)
    ? !imageAnimation
      ? 301
      : 142
    : !imageAnimation
    ? 243
    : 92;
  return (
    <Box
      style={{
        marginTop: marginTop,
        paddingLeft: "6%",
        paddingRight: "6%",
      }}
    >
      {textAnimation && (
        <FadeIn>
          <SlideInUp animate={imageAnimation}>
            <Content />
          </SlideInUp>
        </FadeIn>
      )}
    </Box>
  );
}

export default withWidth()(LandingCentralText);
