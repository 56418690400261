import React from "react";
import { Typography, Grid, ListItem, List, Box } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import ThemeColors from "../config/colors";
import LandingFeatureItem from "./LandingFeatureItem";

function LandingFeaturesContainer({
  data,
  title,
  isRight,
  width,
  addElementsPadding,
}) {
  return (
    <Grid
      xs={12}
      sm={6}
      item
      style={{
        paddingLeft: isRight ? "5%" : 0,
        paddingRight: !isRight ? "5%" : 0,
        marginTop: 50,
      }}
    >
      <Box
        className={
          "p-3 d-flex flex-column align-items-" +
          (isWidthUp("md", width) ? (isRight ? "end" : "start") : "center")
        }
        style={{
          width: "100%",
          backgroundColor: ThemeColors.lightGreenColor,
          borderTopLeftRadius: isRight ? 12 : 0,
          borderBottomLeftRadius: isRight ? 12 : 0,
          borderTopRightRadius: !isRight ? 12 : 0,
          borderBottomRightRadius: !isRight ? 12 : 0,
        }}
      >
        <List>
          <ListItem
            style={{
              justifyContent: isWidthUp("md", width) ? "flex-start" : "center",
            }}
          >
            <Typography variant="h5">{title}</Typography>
          </ListItem>
          {data?.map((e, i) => (
            <LandingFeatureItem
              key={i}
              element={e}
              addElementsPadding={addElementsPadding}
            />
          ))}
        </List>
      </Box>
    </Grid>
  );
}

export default withWidth()(LandingFeaturesContainer);
