import React, { useState } from "react";
import { Element } from "react-scroll";
import NavBar from "../layout/NavBar";
import Landing from "../components/Landing";
import Features from "../components/Features";
import KhaznaCard from "../components/KhaznaCard";
import Services from "../components/Services";
import Benefits from "../components/Benefits";
import Customers from "../components/Customers";
import Footer from "../layout/Footer";
import RegistrationDialog from "../components/RegistrationDialog";

export default function Home() {
  const [open, setOpen] = useState(false);

  const openRegDialog = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Element name="NavBar">
        <NavBar openRegDialog={openRegDialog}>
          <Element name="Landing">
            <Landing openRegDialog={openRegDialog} />
          </Element>

          <Element name="Features">
            <Features openRegDialog={openRegDialog} />
          </Element>

          <Element name="KhaznaCard">
            <KhaznaCard />
          </Element>

          <Element name="Services">
            <Services openRegDialog={openRegDialog} />
          </Element>

          <Element name="Benefits">
            <Benefits openRegDialog={openRegDialog} />
          </Element>

          <Element name="Customers">
            <Customers />
          </Element>

          <Footer />

          <RegistrationDialog open={open} onClose={() => setOpen(false)} />
        </NavBar>
      </Element>
    </React.Fragment>
  );
}
