import React, { useEffect, useState } from "react";
import { makeStyles, Box } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import {
  landingTextDelay,
  landingImageDelay,
  landingFeaturesDelay,
} from "../config/constants";
import LandingFeatures from "./LandingFeatures";
import LandingCentralText from "./LandingCentralText";
import LandingCentralImage from "./LandingCentralImage";

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: "100%",
    overflow: "hidden",
  },
  root: {
    height: "100%",
    width: "100%",
  },
  mainImage: {
    maxWidth: "100%",
    objectFit: "scale-down",
  },
}));

function Landing({ width, allAnimationDone, openRegDialog }) {
  const classes = useStyles();

  useEffect(() => {
    const handleAnimation = () => {
      setTimeout(() => {
        showTextAnimation(true);
      }, landingTextDelay);

      setTimeout(() => {
        showImageAnimation(true);
      }, landingImageDelay);

      setTimeout(() => {
        showFeaturesAnimation(true);

        allAnimationDone && allAnimationDone();
      }, landingFeaturesDelay);
    };

    handleAnimation();
  });

  const landingFeaturesTopSpace = isWidthUp("xl", width)
    ? 190
    : isWidthUp("lg", width)
    ? 120
    : isWidthUp("md", width)
    ? 100
    : isWidthUp("sm", width)
    ? 500
    : 215;

  const [height, setHeight] = useState(1000);

  const [imageHeight, setImageHeight] = useState(200);
  const [featuresHeight, setFeaturesHeight] = useState(200);

  const [textAnimation, showTextAnimation] = useState(false);
  const [imageAnimation, showImageAnimation] = useState(false);
  const [featuresAnimation, showFeaturesAnimation] = useState(false);

  useEffect(() => {
    if (featuresAnimation) {
      if (isWidthUp("sm", width)) {
        updateHeight(featuresHeight + landingFeaturesTopSpace);
      } else {
        updateHeight(imageHeight + featuresHeight);
      }
    }
  }, [
    imageHeight,
    featuresHeight,
    width,
    landingFeaturesTopSpace,
    featuresAnimation,
  ]);

  const updateHeight = (newHeight) => {
    newHeight && setHeight(newHeight);
  };

  return (
    <div
      className={classes.mainContainer}
      // style={{
      //   height: isWidthUp("xl", width)
      //     ? "150vh"
      //     : isWidthUp("lg", width)
      //     ? "180vh"
      //     : isWidthUp("md", width)
      //     ? "130vh"
      //     : isWidthUp("sm", width)
      //     ? "180vh"
      //     : "195vh",
      // }}
    >
      <Box className={classes.root}>
        <Box className="d-flex flex-column align-items-center">
          <LandingCentralText
            textAnimation={textAnimation}
            imageAnimation={imageAnimation}
          />

          <Box
            style={{
              position: "relative",
              width: "100%",
              height: height,
            }}
          >
            <LandingCentralImage
              startFirstAnimation={imageAnimation}
              startSecondAnimation={featuresAnimation}
              style={{ position: "absolute", top: 0 }}
              newHeight={(h) => {
                setImageHeight(h);
              }}
            />
            <LandingFeatures
              startAnimation={featuresAnimation}
              style={{
                position: "absolute",
                top: landingFeaturesTopSpace,
              }}
              openRegDialog={openRegDialog}
              newHeight={(h) => setFeaturesHeight(h)}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default withWidth()(Landing);
