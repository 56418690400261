export const User = {
  initialValue: () => initialValue(),
  toMap: (data) => toMap(data),
};

const initialValue = () => ({
  name: "",
  email: "",
  mobile: "",
  company: "",
  job: "",
});

const toMap = (data) => ({
  name: data?.name,
  email: data?.email,
  mobile: data?.mobile,
  company: data?.company,
  job: data?.job,
});
