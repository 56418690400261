import { createMuiTheme } from "@material-ui/core/styles";
import ThemeColors from "./colors";

const { breakpoints } = createMuiTheme({});

const typograpghy = {
  h1: {
    fontSize: 50,
    fontWeight: 700,
    color: ThemeColors.blueColor,
    [breakpoints.down("md")]: {
      fontSize: 36,
    },
  },
  h2: {
    fontSize: 45,
    fontWeight: 500,
    color: ThemeColors.blueColor,
    [breakpoints.down("md")]: {
      fontSize: 24,
    },
  },
  h3: {
    fontSize: 40,
    fontWeight: 400,
    color: ThemeColors.blueColor,
    [breakpoints.down("md")]: {
      fontSize: 26,
    },
  },
  h4: {
    fontSize: 38,
    fontWeight: 700,
    color: ThemeColors.blueColor,
    [breakpoints.down("md")]: {
      fontSize: 22,
    },
  },
  h5: {
    fontSize: 34,
    fontWeight: 700,
    color: ThemeColors.blueColor,
    [breakpoints.down("md")]: {
      fontSize: 26,
    },
  },
  h6: {
    fontSize: 30,
    fontWeight: 400,
    color: ThemeColors.blueColor,
    [breakpoints.down("md")]: {
      fontSize: 22,
    },
  },
  body1: {
    fontSize: 28,
    fontWeight: 400,
    color: ThemeColors.blueColor,
    [breakpoints.down("md")]: {
      fontSize: 22,
    },
  },
  body2: {
    fontSize: 25,
    fontWeight: 400,
    color: ThemeColors.blueColor,
    [breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
  caption: {
    fontSize: 23,
    fontWeight: 400,
    color: ThemeColors.whiteColor,
    [breakpoints.down("md")]: {
      fontSize: 17,
    },
  },
  subtitle1: {
    fontSize: 21,
    fontWeight: 400,
    color: ThemeColors.whiteColor,
    [breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  subtitle2: {
    fontSize: 17,
    fontWeight: 400,
    color: ThemeColors.whiteColor,
    [breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  button: {
    color: ThemeColors.whiteColor,
    borderRadius: 10,
    textTransform: "none",
    fontSize: 24,
    fontWeight: 700,
    [breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
};

const theme = createMuiTheme({
  direction: "rtl",
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": ["LTAzer"],
      },
    },
    MuiButton: {
      text: {
        fontSize: 24,
      },
    },
    MuiTypography: {
      ...typograpghy,
    },
  },
  palette: {
    primary: {
      main: ThemeColors.blueColor,
      contrastText: ThemeColors.whiteColor,
    },
    secondary: {
      main: ThemeColors.lightBlueColor,
      contrastText: ThemeColors.whiteColor,
    },
    error: {
      main: ThemeColors.lightRedColor,
    },
    text: {
      primary: ThemeColors.blueColor,
      secondary: ThemeColors.greenColor,
      hint: ThemeColors.greyColor,
      disabled: ThemeColors.lightBlueColor,
    },
    background: {
      default: ThemeColors.backgroundColor,
      paper: ThemeColors.backgroundSecondaryColor,
    },
    action: {
      selected: ThemeColors.lightGreyColor,
      active: ThemeColors.greyColor,
      disabled: ThemeColors.lightGreyColor,
    },
    divider: ThemeColors.lightWhiteColor,
  },
  typography: {
    fontFamily: "LTAzer",
    ...typograpghy,
  },
});

export default theme;
