import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import CustomTitle from "./CustomTitle";
import customers from "../services/CustomersService";
import Slider from "react-slick";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: "10%",
    paddingRight: "10%",
  },
}));

function Customers({ width }) {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      style={
        isWidthUp("md", width)
          ? //  web
            {
              paddingTop: 142,
              paddingBottom: 120,
            }
          : isWidthUp("sm", width)
          ? // ipad
            {
              paddingTop: 45,
              paddingBottom: 49,
            }
          : // mobile
            {
              paddingTop: 76,
              paddingBottom: 83,
            }
      }
    >
      <Box align="center">
        <CustomTitle
          style={{
            marginBottom: 50,
          }}
          title="عملائنا"
        />

        <Slider
          rtl={true}
          dots={true}
          arrows={false}
          infinite={true}
          speed={500}
          rows={isWidthUp("md", width) ? 3 : isWidthUp("sm", width) ? 4 : 8}
          slidesPerRow={
            isWidthUp("md", width) ? 5 : isWidthUp("sm", width) ? 4 : 2
          }
          slidesToShow={1}
        >
          {customers?.map((element, index) => (
            <div key={index}>
              <img
                className="my-3"
                style={{
                  width: isWidthUp("sm", width) ? "80%" : "65%",
                  height: isWidthUp("md", width)
                    ? 130
                    : isWidthUp("sm", width)
                    ? 70
                    : 60,
                  objectFit: "scale-down",
                }}
                src={element}
                alt=""
              />
            </div>
          ))}
        </Slider>
      </Box>
    </Box>
  );
}

export default withWidth()(Customers);
