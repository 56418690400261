const customers = [
  `${process.env.PUBLIC_URL}/assets/extras/hassan-allam-2.png`,
  `${process.env.PUBLIC_URL}/assets/extras/ezz.png`,
  `${process.env.PUBLIC_URL}/assets/extras/ibnsina.png`,
  `${process.env.PUBLIC_URL}/assets/extras/elazaby.png`,
  `${process.env.PUBLIC_URL}/assets/extras/orascom-3.png`,
  `${process.env.PUBLIC_URL}/assets/extras/danone.png`,
  `${process.env.PUBLIC_URL}/assets/extras/spinneys.png`,
  `${process.env.PUBLIC_URL}/assets/extras/dina.png`,
  `${process.env.PUBLIC_URL}/assets/extras/elsallab.png`,
  `${process.env.PUBLIC_URL}/assets/extras/redcon.png`,
  `${process.env.PUBLIC_URL}/assets/extras/mansour.png`,
  `${process.env.PUBLIC_URL}/assets/extras/lotus-garments.png`,
  `${process.env.PUBLIC_URL}/assets/extras/gamma.png`,
  `${process.env.PUBLIC_URL}/assets/extras/fresh.png`,

  `${process.env.PUBLIC_URL}/assets/extras/atrium.png`,
  `${process.env.PUBLIC_URL}/assets/extras/americana.png`,
  `${process.env.PUBLIC_URL}/assets/extras/majorel.png`,
  `${process.env.PUBLIC_URL}/assets/extras/ades.png`,
  `${process.env.PUBLIC_URL}/assets/extras/lotus-tex.png`,
  `${process.env.PUBLIC_URL}/assets/extras/elhazek.png`,
  `${process.env.PUBLIC_URL}/assets/extras/eva.png`,
  `${process.env.PUBLIC_URL}/assets/extras/elmokhtabar.png`,
  `${process.env.PUBLIC_URL}/assets/extras/kheir-zaman.png`,
  `${process.env.PUBLIC_URL}/assets/extras/elsafady.png`,
  `${process.env.PUBLIC_URL}/assets/extras/nacita.png`,
  `${process.env.PUBLIC_URL}/assets/extras/reliance.png`,
  `${process.env.PUBLIC_URL}/assets/extras/concrete.png`,
  `${process.env.PUBLIC_URL}/assets/extras/hassan-allam-4.png`,
  `${process.env.PUBLIC_URL}/assets/extras/must.png`,

  `${process.env.PUBLIC_URL}/assets/extras/3s.png`,
  `${process.env.PUBLIC_URL}/assets/extras/gemma.png`,
  `${process.env.PUBLIC_URL}/assets/extras/sapesco.png`,
  `${process.env.PUBLIC_URL}/assets/extras/reliance.png`,
  `${process.env.PUBLIC_URL}/assets/extras/egic.png`,
  `${process.env.PUBLIC_URL}/assets/extras/redcon-spain.png`,
  `${process.env.PUBLIC_URL}/assets/extras/elborg.png`,
  `${process.env.PUBLIC_URL}/assets/extras/rameda.png`,
  `${process.env.PUBLIC_URL}/assets/extras/metro.png`,
  `${process.env.PUBLIC_URL}/assets/extras/ups.png`,
  `${process.env.PUBLIC_URL}/assets/extras/alhayat.png`,
  `${process.env.PUBLIC_URL}/assets/extras/idh.png`,
  `${process.env.PUBLIC_URL}/assets/extras/meg.png`,
  `${process.env.PUBLIC_URL}/assets/extras/ha.png`,
  `${process.env.PUBLIC_URL}/assets/extras/royal-ceramica.png`,

  `${process.env.PUBLIC_URL}/assets/extras/alsagheer.png`,
  `${process.env.PUBLIC_URL}/assets/extras/alfa-1.png`,
  `${process.env.PUBLIC_URL}/assets/extras/alico.png`,
  `${process.env.PUBLIC_URL}/assets/extras/core.png`,
  `${process.env.PUBLIC_URL}/assets/extras/cotton.png`,

  `${process.env.PUBLIC_URL}/assets/extras/intech.png`,
  `${process.env.PUBLIC_URL}/assets/extras/masr-elkheir.png`,
  `${process.env.PUBLIC_URL}/assets/extras/tbs.png`,
  `${process.env.PUBLIC_URL}/assets/extras/mobica.png`,
  `${process.env.PUBLIC_URL}/assets/extras/alfa-2.png`,

  `${process.env.PUBLIC_URL}/assets/extras/hassan-allam-3.png`,
  `${process.env.PUBLIC_URL}/assets/extras/ktc.png`,
  `${process.env.PUBLIC_URL}/assets/extras/nsf.png`,
];

export default customers;
