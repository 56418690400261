// Background colors
const backgroundColor = "#FFFFFF";
const backgroundSecondaryColor = "#F0F6FF";
const backgroundPaperColor = "#1E7597";
const whiteColor = "#FFFFFF";
const darkBlueColor = "#031159";
const lightWhiteColor = "#485286";
const lightGreenColor = "#00BAB01A";

// Button colors
const lightBlueColor = "#5C9EED";

// Text colors
const blueColor = "#22398C";
const accentBlueColor = "#22398CB3";
const greenColor = "#00BAB0";
const greyColor = "#707070";
const lightGreyColor = "#c0c9d2";

// Action colors
const lightRedColor = "#FF6760";

const ThemeColors = {
  backgroundColor,
  backgroundSecondaryColor,
  backgroundPaperColor,
  whiteColor,
  darkBlueColor,
  lightWhiteColor,
  lightGreenColor,
  lightBlueColor,
  blueColor,
  accentBlueColor,
  greenColor,
  greyColor,
  lightGreyColor,
  lightRedColor,
};

export default ThemeColors;
