import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

function RoundedIcon(props) {
  const useStyles = makeStyles((theme) => ({
    socialIconWrapper: {
      backgroundColor: props.backgroundColor
        ? props.backgroundColor
        : "#ffffff",
      padding: "5px",
    },
  }));

  const classes = useStyles();

  return (
    <Box
      align="center"
      className={classes.socialIconWrapper}
      style={
        isWidthUp("lg", props.width)
          ? //  web
            {
              width: "38px",
              height: "38px",
              borderRadius: "76px",
            }
          : {
              width: "30px",
              height: "30px",
              borderRadius: "60px",
            }
      }
    >
      {props.children}
    </Box>
  );
}

export default withWidth()(RoundedIcon);
