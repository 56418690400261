import React from "react";
import { Typography, Grid, Box } from "@material-ui/core";
import {
  khaznaCardFeatures,
  khaznaAppFeatures,
} from "../services/LandingService";
import LandingFeaturesContainer from "./LandingFeaturesContainer";
import CustomButton from "./CustomButton";
import { FadeIn } from "../config/animations";

function LandingFeatures({ newHeight, startAnimation, style, openRegDialog }) {
  return (
    <React.Fragment>
      {startAnimation && (
        <FadeIn>
          <Box
            ref={(ref) => {
              newHeight && newHeight(ref?.clientHeight);
            }}
            align="center"
            style={{ width: "100%", ...style }}
          >
            <Typography variant="h1" className="mt-5">
              مزايا خزنة
            </Typography>

            <Grid container className="d-flex justify-content-between">
              {/* Right */}
              <LandingFeaturesContainer
                isRight
                title="مزايا كارت خزنة"
                data={khaznaCardFeatures}
              />

              {/* Left */}
              <LandingFeaturesContainer
                addElementsPadding
                title="مزايا تطبيق خزنة"
                data={khaznaAppFeatures}
              />
            </Grid>

            <Box className="mt-5">
              <CustomButton
                className="my-5"
                title="تواصل معنا"
                onClick={openRegDialog}
                style={{
                  zIndex: 990,
                }}
              />
            </Box>
          </Box>
        </FadeIn>
      )}
    </React.Fragment>
  );
}

export default LandingFeatures;
