const features = [
  {
    title: "اقبض بدري",
    description:
      "تتيح الخدمة للموظف طلب جزء مستحق من الراتب بداية من ٣٠٪ وحتى ١٠٠٪ أي وقت خلال الشهر وفترة سداد المبلغ تصل إلى ١٠ أشهر.",
    image: `${process.env.PUBLIC_URL}/assets/images/features-mobile-1.png`,
    icon: `${process.env.PUBLIC_URL}/assets/svgs/salary.svg`,
    secondaryIcon: `${process.env.PUBLIC_URL}/assets/svgs/salary-highlighted.svg`,
  },
  {
    title: "ادفع فواتيرك",
    description:
      "دفع الفواتير الخاصة بالمستخدم خلال الشهر عن طريق التطبيق دون الحاجة إلى استخدام النقود الورقية.",
    image: `${process.env.PUBLIC_URL}/assets/images/features-mobile-2.png`,
    icon: `${process.env.PUBLIC_URL}/assets/svgs/bills.svg`,
    secondaryIcon: `${process.env.PUBLIC_URL}/assets/svgs/bills-highlighted.svg`,
  },
  {
    title: "مفردات المرتب",
    description:
      "الإطلاع علي مفردات المرتب وكل التفاصيل التي تخص مرتبك بكل سهولة وبخطوات بسيطة وسريعة من خلال التطبيق",
    image: `${process.env.PUBLIC_URL}/assets/images/payroll.png`,
    icon: `${process.env.PUBLIC_URL}/assets/svgs/ic_payroll_circle_inactive.svg`,
    secondaryIcon: `${process.env.PUBLIC_URL}/assets/svgs/ic_payroll_circle_active.svg`,
  },
  {
    title: "عروض التقسيط",
    description:
      "تقسيط الأجهزة الكهربائية والسلع المعمرة والخدمات المتنوعة من مجموعة من المتاجر الكبرى بدون مقدم أو فوائد أو أي رسوم إضافية.",
    image: `${process.env.PUBLIC_URL}/assets/images/features-mobile-4.png`,
    icon: `${process.env.PUBLIC_URL}/assets/svgs/instalments.svg`,
    secondaryIcon: `${process.env.PUBLIC_URL}/assets/svgs/instalments-highlighted.svg`,
  },
];

export default features;
