import React, { useEffect } from "react";
import {
  CssBaseline,
  MuiThemeProvider,
  StylesProvider,
  jssPreset,
} from "@material-ui/core";

import { create } from "jss";
import rtl from "jss-rtl";
import theme from "./config/theme";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { loaderDelay } from "./config/constants";

import Home from "./views/Home";

import "./styles/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  // Configure JSS
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

  // Hide loader
  useEffect(() => {
    setTimeout(() => {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, loaderDelay);
  }, []);

  return (
    <StylesProvider jss={jss}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Switch>
            <Route path="/" component={Home} />
          </Switch>
        </Router>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default App;
